import React from "react";
import db from "../base";
import uuid from "short-uuid";
import repeatLogo from "../images/repeatLogo.png";
import "../Admin.css";

const generateId = storeName => {
  return `${uuid.generate()}-${storeName}`;
};
// const set = (newObj, callback) => {
//   // Here they do API request with new Obj
//   callback(response);
// }

// // Callback paradigm

// const ref = db.ref(`storesById/${newId}`);
// ref.set({
//   report: "Enter the Report URL Here",
// }, (response) => {
//   if (response.code !== 200) {
//     throw new Error "Uh oh, got a bad response from firebase."
//   }
//   this.setState({ success: true })
//   ref.set('some other thing'. (response) => {
//     this.setState({ step: '2' })
//     ref.set('some other thing again', (response) => {
//       this.setState({ step: '3' })
//     })
//   })
// })
// // here

// // Promise paradigm
// ref.set({
//   report: "Enter the Report URL Here",
//   tab1: "Enter Tab 1 ID Here",
//   tab2: "Enter Tab 2 ID Here",
//   tab2: "Enter Tab 3 ID Here",
//   storeName
// }).then((response) => {
//   this.setState({ success: true })
// }).catch((e) => {

class Admin extends React.Component {
  state = {
    storeName: "Enter New Store",
    success: false
  };
  handleChange = evt => {
    const {
      target: { value: storeName }
    } = evt;
    console.log(evt);
    this.setState({ storeName });
  };
  presentSuccess = () => {
    this.setState({
      success: true
    });
  };
  onSubmit = evt => {
    console.log(evt);
    evt.preventDefault();
    const newId = generateId(this.state.storeName);
    console.log("Created! " + newId);
    const ref = db.ref(`storesById/${newId}`);
    ref
      .set({
        storeName: this.state.storeName,
        reportUrl: "https://www.ericiscool.com",
        tabs: {
          tab1: "ABC",
          tab2: "123",
          tab3: "Easy",
          tab4: "Customer",
          tab5: "Velocity Analytics"
        }
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .then(() => {
        this.presentSuccess();
        this.setState({ storeName: "" });
      });
  };
  render() {
    const success = this.state.success;
    return (
      <div className="admin">
        <div className="admin-header">
          <img className="admin-header-logo" src={repeatLogo} alt="PRZM Logo" />
        </div>
        <div className="admin-body">
          <p>
            Please enter the name of the store you would like to create with NO
            spaces or special characters.
          </p>
          <form className="admin-form">
            <input
              name="storeName"
              autoFocus
              value={this.state.storeName}
              type="text"
              onChange={this.handleChange}
            />
            <br />
            <button onClick={this.onSubmit}>Ceate New Store</button>
          </form>
          {success && <h2>🍾 Success!!! 🎉</h2>}
        </div>
        <div className="admin-footer">
          <img className="admin-footer-logo" src={repeatLogo} alt="PRZM Logo" />
        </div>
      </div>
    );
  }
}

export default Admin;
