import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import db from "../base";
import repeatLogo from "../images/repeatLogo.png";
import "../App.css";

const TAB_DIMENSIONS = {
  "1": [960, 1530],
  "2": [960, 2300],
  "3": [960, 2300],
  "4": [960, 2300],
  "5": [960, 2300]
};

// todo - unknown tab

// const {
//   store: {
//     reportUrl,
//     tabs: { [tabId]: tabUrl }
//   }
// } = this.state;

class App extends React.Component {
  state = {
    loaded: false,
    store: {}
  };
  componentDidMount() {
    const storeId = this.props.match.params.storeId;
    db.ref(`storesById/${storeId}`).once("value", snap => {
      const payload = snap.val();
      const { storeName, reportUrl, tabs } = payload;
      this.setState({
        store: {
          storeName,
          reportUrl,
          tabs: {
            "1": tabs.tab1,
            "2": tabs.tab2,
            "3": tabs.tab3,
            "4": tabs.tab4,
            "5": tabs.tab5
          }
        },
        loaded: true
      });
    });
  }

  iframeBuilder = () => {
    const { tabId } = this.props.match.params;
    const {
      store: { reportUrl, tabs }
    } = this.state;
    const tabUrl = tabs[tabId];
    const [frameWidth, frameHeight] = TAB_DIMENSIONS[tabId];
    return (
      <iframe
        className="report"
        scroll="no"
        src={`${reportUrl}${tabUrl}`}
        height={frameHeight}
        width={frameWidth}
        frameborder="0"
      />
    );
  };

  render() {
    const { storeId } = this.props.match.params;
    const baseUrl = `/${storeId}`;

    if (!this.state.loaded) {
      return <div className="pleaseWait">Spectrum Report Loading</div>;
    }

    return (
      <div className="App">
        <div className="header">
          <img className="header-logo" src={repeatLogo} alt="PRZM Logo" />
          <h1>{this.state.store.storeName}</h1>
        </div>
        <div className="body">
          <div className="tabs">
            <NavLink
              to={baseUrl + "/1"}
              className="menu-button"
              activeClassName="active"
            >
              Overview
            </NavLink>
            <NavLink
              to={baseUrl + "/3"}
              className="menu-button"
              activeClassName="active"
            >
              Product Insights
            </NavLink>
            <NavLink
              to={baseUrl + "/4"}
              className="menu-button"
              activeClassName="active"
            >
              Customer Insights
            </NavLink>
          </div>
          {this.state.loaded ? this.iframeBuilder() : this.renderSpinner()}
        </div>
        <div className="footer">
          <img className="footer-logo" src={repeatLogo} alt="PRZM Logo" />
        </div>
      </div>
    );
  }
}

export default App;
