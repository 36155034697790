import firebase from "firebase";
import "firebase/database";

const config = {
  apiKey: "AIzaSyBdacceqmZPDdJ_hTsYpW-DzuG3qZoGhv0",
  authDomain: "spectrum-client.firebaseapp.com",
  databaseURL: "https://spectrum-client.firebaseio.com",
  projectId: "spectrum-client",
  storageBucket: "spectrum-client.appspot.com",
  messagingSenderId: "911516722602"
};

firebase.initializeApp(config);

const database = firebase.database();
export default database;
